/** @format */

import React from 'react';
import $ from 'jquery';

class Slide extends React.Component {
	state = {
		paused: false,
	};

	componentDidMount() {
		//this.setClass();
	}
	setClass() {
		const rand = Math.floor(Math.random() * 2);
		const randclass = ['scrollbg-a', 'scrollbg-b'];
		console.log('random = ' + rand + ' ' + randclass[rand]);
		return 'fill ' + randclass[rand];
	}

	render() {
		function doAnimations(elems) {
			//Cache the animationend event in a variable
			var animEndEv = 'webkitAnimationEnd animationend';

			elems.each(function () {
				var $this = $(this),
					$animationType = $this.data('animation');
				$this.addClass($animationType).one(animEndEv, function () {
					$this.removeClass($animationType);
				});
			});
		}
		setTimeout(function () {
			var $myCarousel = $('#headerslider'),
				$firstAnimatingElems = $myCarousel.find('.item').find("[data-animation ^= 'animated']");

			$('[data-bg-image]').each(function () {
				$(this).css({ 'background-image': 'url(' + $(this).data('bg-image') + ')' });
			});

			$('[data-bg-color]').each(function () {
				$(this).css({ 'background-color': $(this).data('bg-color') });
			});

			doAnimations($firstAnimatingElems);
		}, 1);

		if (this.props.slidedata === '' || this.props.slidedata === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='item active'>loading</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{Object.keys(this.props.slidedata)
						.slice(0, 1)
						.map((key, index) => (
							<div className='item active' key={key}>
								<div
									className={this.setClass()}
									style={{
										backgroundImage: `url('${this.props.slidedata.url}')`,
										backgroundSize: '120%',
										backgroundPosition: 'center',
									}}>
									<div className='bs-slider-overlay'>
										{/* <div style={{ textAlign: 'center' }} className='controls'>
										<a
											onClick={(e) => this.props.playSlides(e)}
											title='Play Slides.'
											className='list-item-heading mb-0 truncate w-5 w-xs-100 mt-0'
											style={{ cursor: 'pointer', fontSize: '30px', textDecoration: 'none' }}>
											<i className='simple-icon-control-play heading-icon'></i>
										</a>
										<a
											onClick={(e) => this.props.pauseSlides(e)}
											title='Pause Slides.'
											className='list-item-heading mb-0 truncate w-5 w-xs-100 mt-0'
											style={{ cursor: 'pointer', fontSize: '30px', textDecoration: 'none' }}>
											<i className='simple-icon-control-pause heading-icon'></i>
										</a>
									</div> */}
									</div>
									<div className='container movie-slider-container'>
										<div className='row'>
											<div className='col-sm-12 movie-slider-content'>
												<div className='slider-content' style={{ padding: '20px' }}>
													<div className='title' data-animation='animated bounceInRight'>
														{this.props.slidedata.title}
													</div>
													<p
														data-animation='animated bounceInLeft'
														style={{ color: '#fff', fontSize: '14px', lineHeight: '18px' }}>
														Astronomy Picture of the Day - {this.props.getFormattedDate(this.props.slidedata.date)}
													</p>
													<p
														data-animation='animated bounceInLeft'
														style={{ color: '#fff', fontSize: '13px', lineHeight: '18px' }}>
														{this.props.slidedata.explanation}
														<br />
														{this.props.slidedata.copyright !== undefined && (
															<span style={{ float: 'right', fontSize: '13px', lineHeight: '18px' }}>
																&copy; {this.props.slidedata.copyright}
															</span>
														)}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
				</React.Fragment>
			);
		}
	}
}

export default Slide;
