/** @format */

// let's go!/** @format */

import React from 'react';
import { render } from 'react-dom';
import Router from './components/Router';
import './css/packages.min.css';
import './css/default.css';
import './font/iconsmind-s/css/iconsminds.css';
import './font/simple-line-icons/css/simple-line-icons.css';

render(<Router />, document.querySelector('#main'));
