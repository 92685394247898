/** @format */

import React, { Fragment } from 'react';
import $ from 'jquery';
import Slide from './Slide';

class App extends React.Component {
	state = {
		pagedata: {},
		playing: true,
	};

	formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		this.getFormattedDate([year, month, day].join('-'));
		return [year, month, day].join('-');
	}

	randomDate = (start, end) => {
		return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
	};

	stripLeadZero(int) {
		if (int < 10) {
			return int.split('')[1];
		} else {
			return int;
		}
	}
	getFormattedDate(utcDate) {
		let months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		if (utcDate !== null) {
			let dateFrags = utcDate.split('-');
			return months[parseInt(dateFrags[1] - 1)] + ' ' + this.stripLeadZero(dateFrags[2]) + ', ' + dateFrags[0];
		} else return;
	}

	loadData(date) {
		this.setState({ pagedata: {} });
		fetch(
			'https://api.nasa.gov/planetary/apod?date=' + date + '&hd=true&api_key=gI9s3zXBOmgkM9ifclDiwcDzcUAxqUZCbEl2D5to'
		)
			.then((response) => {
				if (!response.ok) {
					//
					throw new Error(response.status);
				} else {
					return response.json();
				}
			})
			.then((data) => {
				this.setState({ pagedata: data });
			})
			.then(() => {
				$('#loader').fadeOut('slow', function () {});
				$('.wrapper').fadeIn('fast', function () {});
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	CounterES5() {
		window.setInterval(
			function () {
				if (this.state.playing === true) {
					this.loadData(this.formatDate(this.randomDate(new Date(1995, 6, 16), new Date())));
					$('#loader').fadeIn('fast', function () {});
					$('.wrapper').fadeOut('fast', function () {});
				} else {
					//$('#loader').fadeIn('fast', function () {});
					//$('.wrapper').fadeOut('fast', function () {});
				}
			}.bind(this),
			30000
		);
	}

	playSlides = (e) => {
		e.preventDefault();

		this.setState({ playing: true });
		this.CounterES5();
	};

	pauseSlides = (e) => {
		e.preventDefault();

		this.setState({ playing: false });
		this.CounterES5();
	};

	componentDidMount() {
		this.loadData(this.formatDate(this.randomDate(new Date(2016, 0, 1), new Date())));
		this.CounterES5();
	}

	render() {
		return (
			<React.Fragment>
				<div id='loader'>
					<div className='loader-ring'>
						<div className='loader-ring-light'></div>
						<div className='loader-ring-track'></div>
					</div>
				</div>
				<div className='wrapper'>
					<header id='header' className='menu-top-left'></header>
					<div className='fullwidth-slider'>
						<div id='headerslider' className='carousel slide'>
							<div className='carousel-inner' role='listbox'>
								<Slide
									slidedata={this.state.pagedata}
									getFormattedDate={this.getFormattedDate}
									stripLeadZero={this.stripLeadZero}
									playSlides={this.playSlides}
									pauseSlides={this.pauseSlides}
								/>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default App;
